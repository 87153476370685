import React, { useEffect, useState, Fragment } from 'react';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHttpClient } from '../../shared/hooks/http-hook';
import SqaureCard from '../../shared/components/UIElements/SqaureCard';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const QASessionCount = ({eventId}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const [value, setValue] = useState('0');

    

    useEffect(() => {
        const fetchPollCount = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/qa/session-count/${eventId}`
            );
            console.log(responseData);
            if (responseData.qaCount) {
               
              setValue(responseData.qaCount);
            }
          } catch (err) {
            console.log(err);
          }
        };
        fetchPollCount();
      }, []);

    return (
        <Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <SqaureCard  value={value} label='Q&A Sessions' isLoading={isLoading}>
                <FontAwesomeIcon icon={faComments} />
            </SqaureCard>
        </Fragment>
    )
}

export default QASessionCount
