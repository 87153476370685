import React, { useEffect, useState, Fragment } from 'react';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHttpClient } from '../../shared/hooks/http-hook';
import SqaureCard from '../../shared/components/UIElements/SqaureCard';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const RegisteredUsers = () => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const [value, setValue] = useState('0');

    useEffect(() => {
        const fetchUserCount = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/user/get-reg-count`
            );
            if (responseData.registeredUserCount) {
              console.log(responseData.registeredUserCount);
              setValue(responseData.registeredUserCount);
            }
          } catch (err) {
            console.log(err);
          }
        };
        fetchUserCount();
      }, []);

    return (
        <Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <SqaureCard  label='Registered Users' value={value} isLoading={isLoading}>
                    <FontAwesomeIcon icon={faUser} />
            </SqaureCard>
        </Fragment>
    )
}

export default RegisteredUsers
