import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import SqaureCard from '../../shared/components/UIElements/SqaureCard';
import { WhosOnlineContext } from '../../shared/context/whosOnline-context';

const OnlineUsers = () => {

    const [list, setList] = useState([]);
    const OnlineList = useContext(WhosOnlineContext).usersConnected;

    useEffect(() => {
      setList(OnlineList);
    }, [OnlineList])

    return (
        <SqaureCard value={list.length} label='Online Users' isLoading={false}>           
            <FontAwesomeIcon icon={faUsers} />
        </SqaureCard>
    )
}

export default OnlineUsers
