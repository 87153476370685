import { faUsersSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState, useContext  } from 'react';

import { WhosOnlineContext } from '../../context/whosOnline-context';

import styles from './WhosOnline.module.css';
import WOList from './WOList';

const WhosOnline = ({locData}) => {

    const [list, setList] = useState([]);
    const OnlineList = useContext(WhosOnlineContext).usersConnected;

    useEffect(() => {
      setList(OnlineList);
      locData(OnlineList);
    }, [OnlineList])

    return (
        <section id={styles.WOL}>
            
            <h1 className={styles.title}>Whos Online</h1>
            {list.length > 0 && <WOList list={list} />}
            {list.length === 0 && 
                <div className={styles.noUsers}>
                <FontAwesomeIcon icon={faUsersSlash} />
                    <h1>The show is empty!</h1>
                </div>
            }

          
        </section>
    )
}

export default WhosOnline;