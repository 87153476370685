import React from 'react';
import styles from './PreviewTitle.module.css';

const PreviewTitle = ({stream}) => {
console.log(stream);
    return (
        <div className={styles.previewTitle}>
            <p className={styles.titleLabel}>Now Showing: <span className={styles.streamLabel}>{stream ? stream.label : 'No stream selected'}</span></p>
        </div>
    )
}

export default PreviewTitle;