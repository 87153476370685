import React from 'react';
import WOItem from './WOItem';

import styles from './WOList.module.css';

const WOList = ({list}) => {

    return (
        <div className={styles.mainList}>
            {list.map(item => (
               <WOItem key={item.id} item={item}/>            
               ))}
        </div>
    )
}

export default WOList;