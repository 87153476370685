import React from 'react';
import Select from '../FormElements/Select';


import styles from './StreamSelector.module.css';


const StreamSelector = ({streams, stream}) => {

        const selectedHandler = event => {
            const selectedStream = streams.find(stream => stream.lang === event.target.value);
            selectedStream && stream(selectedStream);
        }

    return (
        <div id={styles.streamSelector}>
            <h1 className={styles.title}>Stream Preview</h1>
            {streams && streams.length > 1 && <div className={styles.actions}>
                <p className={styles.text}>Choose a stream: </p>
                <Select id="stream" name="stream" className={styles.selector} onChange={selectedHandler}>
                <option value="">- Select a Stream -</option>
                {streams &&
                  streams.map(stream => (
                    <option key={stream.id} value={stream.lang}>
                      {stream.label}
                    </option>
                  ))}
              </Select>
            </div>}
        </div>
    )
}

export default StreamSelector;