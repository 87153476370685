import React, {useState, useEffect, useContext} from 'react';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import { useSelector } from 'react-redux';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import LoadingSpinner from '../UIElements/LoadingSpinner';

import styles from './StreamPreview.module.css';
import VideoPlaceHolder from './VideoPlaceHolder';
import ResponsivePlayer from './VideoPlayer';
import StreamSelector from './StreamSelector';
import PreviewTitle from './PreviewTitle';

const StreamPreview = () => {

    const evid = useSelector(state => state.eid);
    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [streams, setStreams] = useState([]);
    const [stream, setStream] = useState();
    const [streamNotFound, setStreamNotFound] = useState(false);

   useEffect(() => {
       const fetchStreams = async () => {
        try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/streams/`,
              'POST',
              JSON.stringify({
                eventId: evid
              }),
              {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + auth.token,
              }
            );
            console.log(response);
            if (response) {
                if (response.streams) {
                    if (response.streams.length === 0) {
                        setStreamNotFound(true);
                    } else {
                        setStreamNotFound(false);
                        setStreams(response.streams);
                        const defaultStream = response.streams.find(stream => stream.lang === 'en');
                        defaultStream ? setStream(defaultStream) : setStream(response.streams[0])
                    }
                }
            }
            
          } catch (err) {
            console.log(err);
          }

       }
       evid && fetchStreams();
   }, [])

    const selectedStreamHandler = selectedStream => {
        console.log(selectedStream);
        setStream(selectedStream);

    }

    return (
        <section id={styles.streamPreviewWrapper}>
        <div className={styles.container}>
            <ErrorModal error={error} clearError={clearError} />
           
            {isLoading && <LoadingSpinner />}
            {!isLoading && stream && <ResponsivePlayer stream={stream} />}
            {!isLoading && streamNotFound && <VideoPlaceHolder />}
            </div>
            {streams && streams.length > 0 && <PreviewTitle stream={stream}/>}
            {!isLoading && <StreamSelector streams={streams} stream={selectedStreamHandler}/>}
        </section>
    )
}

export default StreamPreview;