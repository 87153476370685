import React, {useState} from 'react';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';

import styles from './VideoPlayer.module.css';

const ResponsivePlayer = ({stream}) => {


  const [playing, setPlaying] = useState(true);
    
      return (
        <div className={styles.playerWrapper}>
          <ReactPlayer 
              className={`react-player ${styles.reactPlayer}`}
              url={stream.uri}
              playing={playing}
              controls={false}
              volume={0.8}
              pip= {true}
              light={false}
              width='100%'
              height='100%'  
              config={{
                vimeo: {
                  playerOptions: { responsive: true },
                  quality: 'auto'
                  }}}
                

            />
           
        </div>
        
      )
    
  }

  export default ResponsivePlayer;