import React, {useState, useEffect} from 'react';
import moment from 'moment';

import styles from './WOItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome, faEdge, faFirefoxBrowser, faOpera, faSafari } from '@fortawesome/free-brands-svg-icons';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';


const WOItem = ({item}) => {

    console.log(item);

    const browser = item.user.stats[0].browserName;
    const [countryCode, setCounrtCode] = useState('GB');
    const deviceType = item.user.stats[0].platform;
    const ip = item.ip;
    const [icon, setIcon] = useState();
    const [deviceIcon, setDeviceIcon] = useState();

    useEffect(() => {
        browserIcon();
        deviceIconHandler();
        //setCounrtCode(item.user.stats[0].countryCode);
        
    }, [item])

    const browserIcon = () => {
        if (browser) {
            switch (browser) {
                case 'Firefox':
                    setIcon(faFirefoxBrowser)
                    break;
                
                case 'Chrome':
                    setIcon(faChrome)
                    break;

                case 'Safari':
                    setIcon(faSafari)
                    break;

                case 'Edge':
                    setIcon(faEdge)
                    break;

                case 'Opera':
                    setIcon(faOpera)
                    break;
            
                default:
                    break;
            }
        }
    }

    const deviceIconHandler = () => {
        if (deviceType) {
            switch (deviceType) {
                case "desktop":
                    setDeviceIcon(faDesktop)
                    break;
                
                case 'mobile':
                    setDeviceIcon(faMobileAlt)
                    break;
            
                default:
                    break;
            }
        }
    }

    return (
        <div className={styles.listItem}>
            {/* {countryCode && <img className={styles.flag} src={`https://eventstreams.co/img/wf/${countryCode}.png`} alt=''/>}
            <p className={styles.ip}>{ip}</p>
            <p className={styles.deviceIcon}>{deviceIcon && <FontAwesomeIcon icon={deviceIcon}/>}</p>
           <p className={styles.icon}>{icon && <FontAwesomeIcon icon={icon}/>}</p> */}

           <div className={styles.userInfoContainer}>
            <h1 className={styles.username}>{item.user.name}</h1>
            <h2 className={styles.email}>{item.user.email}</h2>
           </div>

           <div className={styles.infoContainer}>
           {countryCode && <img className={styles.flag} src={`https://eventstreams.co/img/wf/${countryCode}.png`} alt=''/>}
            <p className={styles.ips}>IP: {ip}</p>
            <p className={styles.deviceIcons}>
              {deviceIcon && <FontAwesomeIcon icon={deviceIcon} />}{' '}
              {deviceType ? deviceType : 'device'}
            </p>
            <p className={styles.icons}>
              {icon && <FontAwesomeIcon icon={icon} />} {browser ? browser : 'browser'}
            </p>
            <p>
              Date/Time: {moment(item.updatedAt).format('MMM Do, h:mm:ss a')}
            </p>
          </div>
          
           {/* <h3 className={styles.loggedin}>{moment(item.updatedAt).format(
                    'MMM Do, h:mm:ss a'
                  )}
            </h3> */}
        </div>
    )
}

export default WOItem;