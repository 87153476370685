import React, {Suspense,Fragment, lazy} from 'react';
import { SocketContext, socket } from './shared/context/socket';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Cookie from 'js-cookie';
import { useDispatch } from 'react-redux';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import SideBar from './shared/components/nav/sidebar';
import Dashboard from './dashboard/pages/dashboard';
import Events from './event/pages/Event';
import Auth from './auth/pages/Auth';
import WhosOnlineContext from './shared/context/whosOnline-context';
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import { EventContext } from './shared/context/event-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';

import './App.css';


const Settings = lazy(() => import('./Settings/pages/Settings'));
const Agenda = lazy(() => import('./agenda/pages/Agenda'));
const Stats = lazy(() => import('./stats/pages/Stats'));
const Polls = lazy(() => import('./polls/pages/polls'));
const QASessions = lazy(()=> import('./qAndA/pages/qAndA'));
const Visitors = lazy(()=> import('./visitors/pages/Visitors'));
const Announce = lazy(()=> import('./Announcments/pages/Announce'));
const Forgot = lazy(()=> import('./auth/pages/ForgotPassword'));
const Reset = lazy(()=> import('./auth/pages/ResetPassword'));
const Content = lazy(()=> import('./content/pages/Content'));

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle
);

function App() {

  const dispatch = useDispatch();

  socket.onAny((event, ...args) => {
    console.log(event, args);
  });

  const {
    userId,
    userFirstName,
    userLastName,
    userProfileImageUrl,
    email,
    token,
    login,
    logout,
  } = useAuth();

  const eventId = Cookie.get('evid');
  if (eventId) {
    //console.log(eventId);
    dispatch({type: 'updateEventId', id: eventId});
  }

  let routes;

  if (!token) {
    routes = (
      <Switch>
        <Route path="/auth" exact>
          <Auth />
        </Route>
        <Route path="/forgot" exact>
          <Forgot />
        </Route>
        <Route path="/reset/:token">
          <Reset />
        </Route>
        <Route path="*" >
          <Auth />
        </Route>
      </Switch>
    );
  } else {
    routes = (
      <Fragment>
        <SocketContext.Provider value={socket}>
          <SideBar />
          <Switch>
          <Route path="/" exact>
              <Events />
            </Route>
          <Route path="/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/agenda" exact>
              <Agenda />
            </Route>
            <Route path="/announcements" exact>
              <Announce />
            </Route>
            <Route path="/qa-sessions" exact>
              <QASessions />
            </Route>
            <Route path="/polls" exact>
              <Polls />
            </Route>
            <Route path="/attendees" exact>
              <Visitors />
            </Route>
            <Route path="/content" exact>
              <Content />
            </Route>
            <Route path="/stats" exact>
              <Stats />
            </Route>
            <Route path="/settings" exact>
              <Settings />
            </Route>
            <Route path="*" >
              <Events />
            </Route>
          </Switch>
        </SocketContext.Provider>
      </Fragment>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        userProfileImageUrl : userProfileImageUrl,
        userFirstName: userFirstName,
        userLastName: userLastName,
        login: login,
        logout: logout,
      }}
    >
    <EventContext.Provider
      value={{
        eventId:eventId
      }}>
      
      <WhosOnlineContext>
      <Router>
       
        <main>
          <Suspense 
            fallback={
              <div className="center">
                <LoadingSpinner />
              </div>
            }
          >
            {routes}
          </Suspense>
        </main>
      </Router>
      </WhosOnlineContext>
      </EventContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;



