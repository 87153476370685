import React, { Fragment, useContext, useState } from 'react';

import Header from '../../shared/components/header/Header';
import MapSmall from '../../shared/components/maps/MapSmall';
import StreamPreview from '../../shared/components/Video/StreamPreview';
import WhosOnline from '../../shared/components/WhosOnline/WhosOnline';
import { EventContext } from '../../shared/context/event-context';
import Summery from '../components/Summery';

import './dashboard.css';
import styles from './dashboard.module.css';

const Dashboard = props => {

  const event = useContext(EventContext);
  console.log(event);
  const [locData, setLocData] = useState([]);

  const updateLocData = data => {
    console.log(data);
    setLocData(()=> [...data])
  }
 
  return (
    <Fragment>
      <div className={"page__wrapper"}>
        <Header title={'Dashboard'} />
        <div className="page-content  pc-stats">
          <div className={styles.pageWrapper}>
          <StreamPreview />  
          <Summery eventId={event.eventId}/>
          <div className={styles.whosonlineWrapper}>
            <WhosOnline locData={updateLocData}/>
            <MapSmall locData={locData}/>
          </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;
