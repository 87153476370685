import React from 'react';

import { Link } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';


import './mainNav.css';

 const MainNav = () => {

    const location = useLocation()
    const url = location.pathname;

    return (
        <div className="main-nav__wrapper">
           { url === '/' ? 
           <ul className="nav-links">
                <li>
                    <Link to="/">Events</Link>
                </li>
            </ul>
            :
            <ul className="nav-links">
                <li>
                    <Link to={`/dashboard`}>Dashboard</Link>
                </li>
                <li>
                    <Link to={`/announcements`}>Announcements</Link>
                </li>
                <li>
                    <Link to={`/attendees`}>Attendees</Link>
                </li>
                <li>
                    <Link to={`/agenda`}>Agenda</Link>
                </li>
                <li>
                    <Link to={`/qa-sessions`}>Q&A Sessions</Link>
                </li>
                <li>
                    <Link to={`/polls`}>Polls</Link>
                </li>
                <li>
                    <Link to={`/content`}>Content</Link>
                </li>
                <li>
                    <Link to={`/stats`}>Stats</Link>
                </li>
                <li>
                    <Link to={`/settings`}>Settings</Link>
                </li>
                
            </ul>
            }
        </div>
    )
}


export default MainNav;