import React, { useEffect, useState, useContext } from 'react';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';


import Input from '../FormElements/Input';
import Select from '../FormElements/Select';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinner from '../UIElements/LoadingSpinner';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import './CreateEventForm.css';

const CreateEventForm = ({success}) => {

    const { REACT_APP_API_URL } = process.env;
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    


    const onSubmit = async (event) => {
     event.preventDefault()
      
        clearError();
    
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/event/create`,
            'POST',
            JSON.stringify({
              title: event.target.title.value,
              subTitle: event.target.subTitle.value,
              date: event.target.date.value
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token,
            }
          );
          console.log(response);
          success()
        } catch (err) {
          console.log(err);
        }
      };

      return (
          <div className='create-event-form-wrapper'>
            <ErrorModal error={error} onClear={clearError} />
            {!isLoading && 
                <form onSubmit={onSubmit}>
                <Input
                    id="title"
                    name="title"
                    type="text"
                    placeholder="required"
                    groupFront="Event Title:"
                />
                <Input
                    id="subTitle"
                    name="subTitle"
                    type="text"
                    placeholder="optional"
                    groupFront="Event Sub Title:"
                />
                <Input
                    id="date"
                    name="date"
                    type="date"
                    groupFront="Event Date:"
                />
                <div className='create-event-actions'>
                    <Button title='create' className='button-secondary' />
                </div>
                </form>
            }
          </div>
      )
}

export default CreateEventForm;