import React, { useEffect, useState, useContext } from 'react';

import { useSelector } from 'react-redux';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

import Button from '../../shared/components/UIElements/Buttons/Button';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import ContentContainer from '../../shared/components/UIElements/ContentContainer';
import EventListItem from './EventListItem';

import './EventList.css';

const EventList = ({selectedEvent, createEvent}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/event/events`,
              'POST',
              {
                'Content-Type': 'application/json',
                Authorization: auth.token,
              }
            );      
            if (responseData.events ) {
                setEvents(responseData.events)
                if (responseData.events.length === 1) {
                  selectedEvent(responseData.events[0].id);
                }
            }
           
          } catch (err) {
            console.log(err);
          }
        };
        fetchEvents();
      }, []);

    return (
        <section id='event-list'>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <div className='center'><LoadingSpinner /></div>}
            {events.length > 0 ?
            <div className='event-list-container'>
                <ContentContainer title='Select an event'>
                    {events.map(e => (
                        <EventListItem key={e.id} id={e.id} title={e.title} subTitle={e.subTitle} selected={selectedEvent}/>
                    ))}
                </ContentContainer>
            </div>
            : 
            <div className='no-event-list'>
            {!isLoading && 
                <ContentContainer title='My Events'>
                    <div className='no-event-textbox'>
                    <h1 className='no-events-title'> No events yet!</h1>
                    <p className='no-events-text'>Lets get started!, create a new event</p>
                    <Button className='button-primary' title='Create Event' onClick={createEvent}/>
                    </div>
                
                </ContentContainer>
            }
            </div>
            }
        </section>
    )
}

export default EventList;