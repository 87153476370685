import React, {useState, useEffect} from 'react';
import ReactMapGL, { Marker } from 'react-map-gl'
import PulseDot from '../UIElements/PulseDot';

import styles from './MapSmall.module.css';
import mapboxgl from 'mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const defaultViewport = {
    width: "100%",
    height: "100%",
    latitude: 48.430472,
    longitude: 20.0,
    zoom: 3,
    bearing: 0,
    pitch: 0
}

const MapSmall = ({locData}) => {

    const MAPBOX  = 'pk.eyJ1IjoiaGVsb2dhbGUiLCJhIjoiY2t2cmE0cDNiMmFmcDJxb3U2emk0enQ5cCJ9.leDZltap1V0SH5L_JotXDQ';
    const [viewport, setViewport] = useState();
    const [locs, setLocs] = useState([]);
    const [isHidden, setIsHidden] = useState(true);
    
    useEffect(() => {
        if (viewport && viewport.latitude === 48.430472 && viewport.longitude === 20.0 ) {
            setIsHidden(true);
        } else {
            setIsHidden(false);
        }
    }, [viewport])

    useEffect(() => {
        setViewport({
              width: "100%",
              height: "100%",
              latitude: 48.430472,
              longitude: 20.0,
              zoom: 3,
              bearing: 0,
              pitch: 0
          })
    },[])

    const viewportChnageHandler = vp => {
        setViewport(vp)
    }

    useEffect(() => {
        console.log(locData);
        let conns = []
        if (locData) {
            locData.map(conn => {
                conns.push( conn.user.stats[0])
        })
        }
        console.log(conns);
        setLocs(conns);
    }, [locData])

    const resetViewHandler = () => {
        setViewport(defaultViewport);
    }

    return (
        <div className={styles.mapContiner}>
            <ReactMapGL 
                {...viewport} 
                mapStyle='mapbox://styles/helogale/ckvss3u6h1c7y16qug3a04ln9'  
                mapboxApiAccessToken={MAPBOX} 
                onViewportChange={vp => viewportChnageHandler(vp)}>
           {locs.length > 0 && locs.map((loc, idx) => (
            loc.coordinates && <Marker key={idx} latitude={parseFloat(loc.coordinates.coordinates[0])} longitude={parseFloat(loc.coordinates.coordinates[1])}><PulseDot/></Marker>
           )) }
            </ReactMapGL>
            <button className={`${styles.resetBtn} ${isHidden && styles.hidden}`} type='button' onClick={resetViewHandler} >reset View</button>
        </div>
    )
    
}

export default MapSmall
