
import React, {useState, useEffect}  from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../shared/components/header/Header';
import SubMenu from '../../shared/components/nav/subMenu';
import EventList from '../components/EventList';
import CreateEvent from '../components/CreateEvent';
import Cookie from 'js-cookie';

const subMenu = [
  {
    id: 0,
    title: 'My Events'
  },
  {
    id: 1,
    title: 'Create Event'
  }
]

const Events = props => {
  const dispatch = useDispatch();
  let history = useHistory();
  const [showEventList, setShowEventList] = useState(true);
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [eventListData, setEventListData] = useState();

  const eventData = useSelector(state => state.events);

  const updateId = (eventId) => {
    const id = dispatch({type: 'updateEventId', id: eventId});
    console.log(id);
  }


useEffect(() => {
  if (eventData) {
    console.log(eventData);
    setEventListData(eventData)
  } else {
    //get from server 
    console.log('get from server');
  }
  
}, [eventData])

  const selectVisitorHandler = id => {
    Cookie.set('evid',  id )
    updateId(id);
    console.log(id);
    history.push(`/dashboard`);
   
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowAddEvent(false);
        setShowEventList(true);        
        break;
      case 1:
        setShowAddEvent(true);
        setShowEventList(false);        
        break;
    
      default:
        break;
    }
  }
 

  
  return (
    <div className="page__wrapper">
    <Header title={'Events'} />
    <SubMenu items={subMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
      {showEventList && <EventList selectedEvent={selectVisitorHandler} createEvent={()=>subMenuStateHandler(1)}/>}
      {showAddEvent && <CreateEvent success={()=>subMenuStateHandler(0)} />}
  
    </div>
  </div>
  );
};

export default Events;
