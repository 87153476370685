import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
import { SocketContext } from '../context/socket';
import { useHttpClient } from './http-hook';
import { EventContext } from '../../shared/context/event-context';
import { WhosOnlineContext } from '../context/whosOnline-context';

const useWhosOnline = () => {
  const { REACT_APP_API_URL } = process.env;
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const eventId = useContext(EventContext);
  const updateList = useContext(WhosOnlineContext).updateList;

  // listen for new questions on WS
  useEffect(() => {
    socket.on('CONN', data => {
      console.log(data.action);
      fetchList();
    });
    return () => {
      socket.off('CONN');
    };
  }, [socket]);

  useEffect(() => {

    if (auth.isLoggedIn) {
      eventId && fetchList();
    }
  }, []);

  const fetchList = async () => {
    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/conn/`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: auth.token,
        }
      );
      updateList(responseData);
    } catch (err) {
      console.log(err);
    }
  };
};

export default useWhosOnline;
