import React from 'react';
import Logo from '../UIElements/logo';
import MainNav from './mainNav';

import './sidebar.css';

const Sidebar = props => {

    return (
        <div className="sidebar__wrapper">
            <Logo imgClassName='sb-logo'/>
            <MainNav  />


        </div>

    )
}

export default Sidebar;