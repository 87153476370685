import React from 'react';

import styles from './VideoPlaceHolder.module.css';

const VideoPlaceHolder = ({message}) => {

    return (
        <div className={styles.outerWrapper}>
            <div className={styles.placeholder}>
                <div className={styles.innerFrame}>
                    <h1 className={styles.title}>No Live Stream Set</h1>
                    <p className={styles.text}>Please set a stream in control panel</p>
                </div>
            </div>
        </div>
    );
}

export default VideoPlaceHolder;