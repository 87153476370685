import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../context/auth-context';
import AudienceCount from '../Analytics/AudienceCount';

import './Header.css';
import useWhosOnline from '../../hooks/online-hook';

const Header = props => {
  const { userFirstName, userProfileImageUrl, logout } = useContext(
    AuthContext
  );
  const [name, setName] = useState('');
  const [profileImage, setProfileImage] = useState('');

  useWhosOnline();

  useEffect(() => {
    setName(userFirstName);
    setProfileImage(userProfileImageUrl);
  }, [userFirstName, userProfileImageUrl]);

  return (
    <div className="page-header__wrapper">
      <h1 className="page-title">{props.title}</h1>
      <div className="tutor-welcome__wrapper">
      <AudienceCount />
        <button onClick={logout} className="logout">
          <FontAwesomeIcon icon="sign-out-alt" />
        </button>
      </div>
    </div>
  );
};

export default Header;
